.login-catch {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

button {
  transition: all ease .2s;
  cursor: pointer;
  border: 1px solid black;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  &:hover {
    background-color: black;
    color: white;
  }
}

.login-container {
  padding-top: 100px;
  text-align: center;
  h1 {
    text-align: center;
  }
  .form {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    label {
      display: block;
      font-weight: 800;
      font-size: 12px;
      text-align: center;
    }
    input {
      min-height: 35px;
      font-size: 18px;
      display: block;
      width: 100%;
      margin: 0 auto;
      display: block;
      margin: 2em auto;
      padding: 0.5em 0.25em;
      border: none;
      border-radius: 0.2em;
      font-size: 1.5em;
      text-align: center;
      box-shadow: 0 0 .5em 0em rgba(0,0,0,0.1);
      &:focus {
        outline:none;
      }
    }
  }
}
