.admin-wrapper {
  background-color: white;
  height: 100%;
  padding-bottom: 100px;
  button {
    width: 150px;
    float: right;
    margin-left: 25px;
  }
  .video-admin-container {
    margin-top: 50px;
    h1 {
      margin: 0rem 2%;
    }
    .video-cell {
      margin-top: 50px;
      height: auto;
      padding-bottom: 30px;
      background-color: rgba(black, .05);
      border-radius: 4px;
      position: relative;
      -webkit-box-shadow: 6px 2px 31px 6px rgba(0,0,0,0.11);
      -moz-box-shadow: 6px 2px 31px 6px rgba(0,0,0,0.11);
      box-shadow: 6px 2px 31px 6px rgba(0,0,0,0.11);
      h1 {
        display: inline;
      }
      a {
        text-decoration: none;
      }
      .admin-button.float {
        float: left;
      }
      .admin-button.warn {
        margin-top: 100px;
        border: 1px solid red;
        p {
          color: red;
        }
      }
      .admin-button {
        margin-left: 10px;
        margin-top: 25px;
        margin-right: 15px;
        cursor: pointer;
        width: 35%;
        padding: 5px;
        border: 1px solid black;
        transition: all ease .4s;
        &:hover {
          border: 1px solid rgba(black, .5);
          p {
            color: rgba(black, .5);
          }
        }
        p {
          text-decoration: none;
          transition: all ease .4s;
          margin: 0px;
          font-size: 20px;
        }
      }
      video {
        margin: auto;
        // position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        max-height: 220px;
        background-color: transparent;
        background-color: black;
      }
      // border: 1px solid black;
    }
  }
}
