@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);

// fonts
$font-family: 'Lato', Helvetica, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;
$font-height: 1.5;

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: $font-weight-light;
}

.font-regular {
  font-weight: $font-weight-regular;
}

.font-heavy {
  font-weight: $font-weight-heavy;
}


// colors
$dark-grey: #333447;
$dark-gray: #333447; // for the Americans

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

* {
  font-family: $font-family;
  // color: $dark-grey;
  line-height: $font-height;
}

p, h1, h2, h3, h4, h5, a, i, .file-upload {
 -webkit-tap-highlight-color: transparent;
}
