#newClient {
  background-color: white;
  height: 100vh;
  .background {
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center;
    // -webkit-filter: blur(2px);
    // -moz-filter: blur(2px);
    // -o-filter: blur(2px);
    // -ms-filter: blur(2px);
    // filter: blur(2px);
    z-index: 1;
  }
}

.submit {
  position: relative;
  z-index: 100;
  cursor: pointer;
  display: block;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  border: 1.5px solid white;
  background-color: white;
  font-size: 20px;
  font-family: sans-serif;
  text-transform: uppercase;
  color: black;
  font-weight: 800;
}

.noUpload {
  border: 1.5px solid red;
  color: red;
}


p {
  font-size: 40px;
  color: black;
  text-align: center;
  font-family: sans-serif;
}

.fail {
  color: red;
}

.wrapper {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .file-upload {
  z-index: 100;
  height: 200px;
  width: 200px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid white;
  overflow: hidden;
  background-image: linear-gradient(to bottom, white 50%, transparent 50%);
  background-size: 100% 200%;
  transition: all 1s;
  color: black;
  font-size: 100px;
}
.wrapper .file-upload input[type='file'] {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.wrapper .file-upload:hover {
  background-position: 0 -100%;
  color: white;
}
